import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import Fade from 'react-reveal/Fade';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Card from 'common/components/Card';
import Button from 'common/components/Button';
import AboutUsSectionWrapper from './aboutUsSection.style';
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

const treatsBtnClick = () => {
  window.triggerTreats('.treat-wrapper');
}

const AboutUsSection = ({
  row,
  col,
  title,
  description,
}) => {
  const Data = useStaticQuery(graphql`{
  image1: file(relativePath: {eq: "image/portfolio/group/group-image1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image2: file(relativePath: {eq: "image/portfolio/group/slykeborggolf.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image3: file(relativePath: {eq: "image/portfolio/group/iziai.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  portfolioJson {
    aboutus {
      id
      title
      icon
    }
  }
}
`);

  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Helmet>
        <script src={withPrefix('treats.js')} type="text/javascript" />
    </Helmet>
      <Box className="row" {...row}>
        <Box className="col" {...col}>
        <Heading  content="Take a sneak peak.. Go on." {...title} />
        <Text className="slogan"
                  content="Just to remind you, images don't mean anything when it comes to UX. Don't get fooled by Dribbble shots."
                  {...description}
                />
          <Card className="group-gallery">
            <Box className="col1">
              <Fade top delay={30}>
                <Text
                  content="Complex Data-Visualization UX Strategy"
                  {...description}
                />
                <GatsbyImage
                  image={(Data.image1 !== null) | undefined
                    ? Data.image1.childImageSharp.gatsbyImageData
                    : {}}
                  src={Data.image1.childImageSharp.gatsbyImageData.src}
                  title="Medallia"
                  objectFit="contain"
                  alt="Medallia" />
              </Fade>
              <Fade left delay={60}>
              <Text
                  content="AI/Machine Learning Software Interactions"
                  {...description}
                />
                <GatsbyImage
                  image={(Data.image3 !== null) | undefined
                    ? Data.image3.childImageSharp.gatsbyImageData
                    : {}}
                  src={Data.image3.childImageSharp.gatsbyImageData.src}
                  title="IzziAI"
                  objectFit="contain"
                  alt="IzziAI" />
              </Fade>
            </Box>
            <Box className="col2">
              <Fade bottom delay={90}>
              <Text
                  content="Touchscreen Acessibility Adaptation"
                  {...description}
                />
                <GatsbyImage
                  image={(Data.image2 !== null) | undefined
                    ? Data.image2.childImageSharp.gatsbyImageData
                    : {}}
                  src={Data.image2.childImageSharp.gatsbyImageData.src}
                  title="Golf24"
                  objectFit="contain"
                  alt="Golf24" />
              </Fade>
            </Box>
          </Card>
          <div className="wrapper_pfl">
          <Heading  content="But, where's the portfolio?" className="braniac_h" {...title} />
          <Text className="slogan second"
                  content="Having full discretion and respect for my clients is my TOP priority, so I choose not to have a public portfolio. If you are curious about my recent work just shoot me a message."
                  {...description}
                />
          <div className="treat-wrapper">
          <Button
                title="Useless button"
                className="treat-button"
                onClick={treatsBtnClick}
              />
              </div>
              <Text className="slogan"
                  mt={3}
                  content="...BUT It gives treats 🍭"
                  {...description} />
          </div>
        </Box>
        {/* <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={
                <Heading
                  content="Great Responsive & Strong Competitive People"
                  {...title}
                />
              }
              description={
                <Text
                  content="Some hardworking People are Working Day and Night to provide you highly scalable product . "
                  {...description}
                />
              }
            />
          </Box>
          <Box {...textArea}>
            {Data.portfolioJson.aboutus.map((feature, index) => (
              <FeatureBlock
                key={`feature_point-${index}`}
                icon={<i className={feature.icon} />}
                iconPosition="left"
                title={<Heading content={feature.title} {...featureTitle} />}
              />
            ))}
            <Button title="DISCOVER ITEM" {...btnStyle} />
          </Box>
        </Box> */}
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '65%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    as: 'h2',
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['26px', '20px', '20px', '26px'],
    lineHeight: '1.5',
    fontWeight: '700',
    color: '#111',
    letterSpacing: '-0.025em',
    mb: '12px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#111',
    lineHeight: '1.75',
    mb: '20px',
    fontWeight: '700',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#111',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    mt: '20px',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default AboutUsSection;
