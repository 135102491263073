import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { portfolioTheme } from 'common/theme/portfolio';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/Portfolio/portfolio.style';

import BannerSection from 'containers/Portfolio/Banner';
import Navbar from 'containers/Portfolio/Navbar';
import ClientsSection from 'containers/Portfolio/Clients';
import ContactSection from 'containers/Portfolio/Contact';
import Footer from 'containers/Portfolio/Footer';
import Testimonials from 'containers/Portfolio/Testimonials';
import SEO from 'components/seo';
import AboutUsSection from 'containers/Portfolio/AboutUsSection';
import MentorSection from 'containers/Portfolio/MentorSection';
import Helmet from 'react-helmet';

export default () => {
  return (
    <ThemeProvider theme={portfolioTheme}>
      <Helmet>
        <meta property="og:image" content={'/images/og-image.png'} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <Fragment>
        <SEO title="It's Home | IB" />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>

          <BannerSection />
          <ClientsSection />
          <MentorSection />
          <Testimonials />
          <AboutUsSection />
          <ContactSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
