import styled from 'styled-components';

export const ClientsImage = styled.div`
  position: relative;
  padding: 20px 28px;
  flex-shrink: 0;
  &:hover {
    img {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 30px 30px;
  }
  @media only screen and (max-width: 624px) {
    padding: 30px 30px;
  }
  .container {
    @media only screen and (min-width: 1367px) {
      padding: 30px 30px;
    }
  }


  img {
    filter: grayscale(0);
    opacity: 1;
    transition: 0.3s ease-in-out;
    @media only screen and (max-width: 480px) {
      filter: grayscale(0);
      opacity: 0.8;
    }
  }
`;
