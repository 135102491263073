import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Icon from 'react-icons-kit';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BannerWrapper from './banner.style';

import { cornerDownRight } from 'react-icons-kit/feather/cornerDownRight';

const BannerSection = ({
  row,
  contentArea,
  greetingStyle,
  nameStyle,
  designationStyle,
  aboutStyle,
  roleStyle,
  roleWrapper,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        SOCIAL_PROFILES {
          icon
          url
        }
      }
    }
  `);

  return (
    <BannerWrapper id="banner_section">
      <Container noGutter mobileGutter width="1200px" >
        <Box {...row} >
          <Box {...contentArea} className="mHeader" width="100%">
            <Heading content="Generic introduction" {...greetingStyle} />
            <Heading className="nice_font" content="SUX" {...nameStyle} />
            <Heading content="I know you agree with me." className="agr" {...designationStyle} />
            <Box {...roleWrapper}>
              <Icon
                icon={cornerDownRight}
                style={{ color: '#3444f1' }}
                size={22}
                
              />
              <Heading content="Currently working on making some people happy" {...roleStyle} />
            </Box>
            <Text
              content="What's wrong with all these 'Hello I'm..'? Nobody is demonstrating out-of-the-box thinking. So what's the point? It's my page, I will put whatever I like as content. If you don't like it, just don't read it. I'm paying for the hosting either ways...⚡️"
              {...aboutStyle}
            />
          </Box>
          {/* <Box {...imageArea} className="image_area">
            <Image src={PersonImage} alt="Mat Helme" />
          </Box> */}
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  greetingStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
  aboutStyle: PropTypes.object,
  roleStyle: PropTypes.object,
  roleWrapper: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  contentArea: {
    width: ['100%', '100%', '50%', '40%'],
    p: ['65px 0 80px 0', '65px 0 80px 0', '80px 0 60px 0', '0'],
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  imageArea: {
    width: ['100%', '100%', '50%', '60%'],
    flexBox: true,
    alignItems: 'flex-end',
  },
  greetingStyle: {
    as: 'h4',
    color: '#111',
    fontSize: ['18px', '18px', '18px', '18px', '20px'],
    fontWeight: '400',
    mb: '8px',
    mt: ['10px', '10px', '10px', '20px', '80px'],
  },
  nameStyle: {
    as: 'h2',
    color: '#111',
    fontSize: ['200px', '200px', '200px', '200px', '250px'],
    fontWeight: '400',
    mb: '6px',
    justifyContent: 'center',
  },
  designationStyle: {
    as: 'h3',
    color: '#111',
    justifyContent: 'center',
    fontSize: ['18px', '18px', '18px', '18px', '20px'],
    fontWeight: '400',
    mb: ['30px', '30px', '25px', '30px', '30px'],
  },
  roleWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mb: '28px',
  },
  roleStyle: {
    as: 'h4',
    fontSize: ['18px', '18px', '18px', '18px', '20px'],
    fontWeight: '700',
    justifyContent: 'center',
    color: '#111',
    mb: '0',
    ml: '10px',
  },
  aboutStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#111',
    justifyContent: 'center',
    lineHeight: '1.5',
    mb: '50px',
  },
};

export default BannerSection;
