import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const AboutUsSectionWrapper = styled.section`
  padding: 80px 0 50px;
  overflow: hidden;

  @media (max-width: 990px) {
    padding: 60px 0 40px 0;

    .col1,.col2 {
      width: 90% !important;
      margin:0 auto !important;
    }

  }
  .col {
    align-self: center;
  }

  .group-gallery {
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    .col1 {
      width: calc(60% - 30px);
      margin-right: 30px;
      .gatsby-image-wrapper {
        margin-bottom: 30px;
      }
    }
    .col2 {
      width: calc(40% - 30px);
      align-self: center;
      margin-right: 30px;
    }
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 30px;
      object-fit: contain;
    }

    @media only screen and (max-width: 480px) {
      padding-left: 30px;
      padding-right: 30px;
    }

  }

  .feature__block {
    align-items: center;
    margin-bottom: 14px;
    .icon__wrapper {
      color: ${themeGet('colors.primary', '#10ac84')};
      margin-right: 10px;
    }
    .content__wrapper {
      h2 {
        margin-bottom: 0;
      }
    }
  }

  // .reusecore__button {
  //   margin-top: 36px;
  //   transition: all 0.3s ease;
  //   &:hover {
  //     box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
  //   }
  // }

h2 {
  display:flex;
  justify-content: center;
  text-align:center;
  @media only screen and (max-width: 480px) {
    text-align: left !important;
    }
}

.slogan {
  font-size: 15px;
  font-weight: normal;
  line-height: 2.3;
  margin-bottom: 30px;
  color: #111;
  display:flex;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 480px) {
    padding-left: 30px;
    padding-right: 30px;
    // text-align: left;
    line-height: 1.40;
  }

}

.second {
  @media only screen and (max-width: 480px) {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    line-height: 1.40;
  }
}


.portfolio_button {
  display: flex;
  margin: 20px auto 50px auto;
  border-radius: 0;
  border: 2px solid ${themeGet('colors.borderColor', '#1b1e25')};
  background-color: transparent;
  position: relative;
  min-height: 50px;
  text-transform: initial;
  transition: 0.2s ease-in-out;
  &:before {
    content: '';
    background-color: #EAF2E3;
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    display: block;
    z-index: -1;
    top: 8px;
    left: 8px;
    transition: inherit;
  }
  &:hover {
    border-color: transparent;
    &:before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .btn-text {
    color: ${themeGet('colors.headingsColor', '#0f2137')};
  }
}


.treat-button {
    display: inline-flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    font-weight: 800; 
    
    border-radius: 0;
    border: 3px solid ${themeGet('colors.borderColor', '#1b1e25')};
    background-color: #fcbe00;
    position: relative;
    min-height: 50px;
    text-transform: initial;
    overflow:hidden;
    font-size: 1.125rem;
    padding: .6875rem 1.25rem 1.125rem;
    min-height: 3.625rem;
    letter-spacing: 1.8px;
    line-height: .0769230769;
    color: #111;
    transition: transform .1s cubic-bezier(.5, 0, .5, 1), box-shadow .2s;
    outline: none;
  
  &:after {
    content: '';
    display: block;
    height: .4125rem;
    background: rgba(0,0,0,.15);
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
  }
  
  &:active {
    transform: scale(0.8) translateY(10%);
    transition-timing-function: cubic-bezier(.5, 0, .5, 1);
  }

  &:hover 
  {
    &:after {
      transform:translateY(4px);
    }
  }

  @media only screen and (max-width: 480px) {
    width:100% !important;
  }

}

.treat-wrapper {
  text-align:center;
}

.wrapper_pfl {
  padding-right:30px;
  padding-left: 30px;
  @media only screen and (max-width: 480px) {
    margin-top: 40px;
  }
}

.wrapper_pfl * {
  line-height: 1.75;
  @media only screen and (max-width: 480px) {
    line-height: 1.34;
  }
}

`;

export default AboutUsSectionWrapper;
