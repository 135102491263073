import styled from 'styled-components';

const BannerWrapper = styled.section`
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-top: 80px;
  display: flex;
  align-items: flex-end;
  text-align:center;
  @media (min-width: 991px) {
    min-height: 570px;
  }

  .banner_section * {
    text-align:center;
  }

.mHeader h3, .mHeader div, .mHeader p {
    @media only screen and (max-width: 480px) {
      text-align: left;
      }
  }

  .agr {
    @media only screen and (max-width: 480px) {
      text-align: center !important;
      }
  }

  .nice_font {
    font-family: 'VT323', 'serif';
    font-display: block;
    font-weight:700;
    padding-top:45px;
    padding-bottom:15px;
    margin-top: -78px;
    margin-bottom: -20px;
    color: #3e32d4;
    text-shadow: 7px 4px 0px rgba(30, 21, 147, 0.9), 15px 8px 0px rgba(158, 254, 250, 1) ;
  }
  
  .image_area {
    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export default BannerWrapper;
